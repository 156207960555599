import React from "react"
import { NavLink } from "react-router-dom";
import ImageGallery from "../components/ImageGallery"
import AOS from "aos";
import "aos/dist/aos.css";
import { Component } from 'react';

import { ReactComponent as InstagramLogo } from "../icons/instagram.svg"
import { ReactComponent as LinkedinLogo } from "../icons/linkedin.svg"
import { ReactComponent as BehanceLogo } from "../icons/behance.svg"

export default class Home extends Component {
    constructor(props) {
        super(props)

    }

    componentDidMount() {
        AOS.init({
            duration: 500
        })
    }
    render() {
        return (
            <main className="homeClass">
                <section className="homeSpacer">
                    <div className="spacerOverlay"><p data-aos="fade-up">Home</p></div>
                </section>
                <ImageGallery />
                <NavLink to="/projects"
                    className="projectsButton">
                    Projects
                </NavLink>
                <section className="paragraphSection">


                    <p className="homeParagraph"><p className="homeTitle">Hello!</p>
                        I'm Francesca, a freelance illustrator and web designer based in Italy.
                    </p>
                    <div className=" icone-social">
                        <a href="https://www.instagram.com/effeemme_art/"
                            className="logo " target="_blank" rel="noreferrer">
                            <InstagramLogo className="logo" fill="black" />

                        </a>
                        <a href="https://www.linkedin.com/in/francesca-mui%C3%A0-6135ba175/"
                            className="logo " target="_blank" rel="noreferrer">
                            <LinkedinLogo className="logo" fill="black" />

                        </a>
                        <a href="https://www.behance.net/francescamuia"
                            className="logo " target="_blank" rel="noreferrer">
                            <BehanceLogo className="logo" fill="black" />

                        </a>
                    </div>

                </section>
            </main>
        )
    }
}