import React from "react"
import profilo from "../images/IMG_ABOUT.jpg"
import { NavLink } from "react-router-dom";
export function About() {
    return (
        <main className="homeClass">
            <section className="homeSpacer">
                <div className="spacerOverlay"><p data-aos="fade-up">About</p></div>
            </section>
            <div className="aboutSection">

                <div className="aboutPhoto" >
                    <img style={{ height: "100%" }} src={profilo} />
                </div>
                <div className="textSection">
                    Hi, I'm Francesca, you can find me as <a href="https://www.instagram.com/effeemme_art/" class="siteLink" style={{ fontFamily: "poppins-italic" }}>EFFEEMME</a> on Instagram.<br />
                    I am an illustrator and web designer with a somewhat peculiar training path. My passion for drawing was born in my childhood, in fact as a child I was constantly drawing characters from my favorite cartoons and comics. This love for Japanese animation and manga stuck with me for a long time, so much that I ended up graduating in oriental languages. <br />
                    After university I wanted to devote myself to drawing, not only in its oriental interpretation but above all in its modern forms by starting and following various online and real-life courses on digital illustrations and web design. And while approaching this amazing world I gradually discovered lettering too, a discipline that immediately struck me mesmerized and so I decided to deepen my understanding of it by studying the theory on manuals and following courses created by various lettering artists that I admire. <br />
                    As of now this path has led me to create websites for private users and institutions like (the Lazio Region) alike and of course my own portfolio site filled with all the illustrations I realize. Illustrations that you can find <NavLink onClick={() => { document.getElementById("navcheck").checked = false; }} to="/projects"
                        className="siteLink" style={{ fontFamily: "poppins-italic" }}>
                        here
                    </NavLink>, if you want to take a look.
                </div>
            </div>
        </main>
    )
}